.neonText {
    color: #ff4;
    text-shadow: 0 0 20px #000, 0 0 30px #4dffa6, 0 0 40px #4dffa6, 0 0 50px #4dffa6, 0 0 60px #4dffa6, 0 0 70px #4dffa6, 0 0 80px #4dffa6;
  }
 
.zoom {
    transition: .01s;
}  

.zoom:hover {
    transform: scale(1.1);
}

.zoom-small {
    transition: .01s;
}  

.zoom-small:hover {
    transform: scale(1.01);
}

/* Button styles for latest etc box titles */

/*
.titleblue,.hover-titleblue:hover{color:#fff!important;background-color:#3d6a81!important}
*/
.titleblue{ background-color:#3d6a81 }

.buttoncolor{color:#000;background-color:#fff}
.buttoncolor:hover{color:#000;background-color:#aaa}

.buttoncolordark{color:#fff;background-color:#000}
.buttoncolordark:hover{color:#fff;background-color:#010101}


/* Elements with equal sizes */

.col-container {
    display: table; /* Make the container element behave like a table */
    width: 100%; /* Set full-width to expand the whole page */
}
  
.col {
    display: table-cell; /* Make elements inside the container behave like table cells */
}

/* Banner for cookies */

.cookies-eu-banner {
    background: #444;
    color: #fff;
    padding: 6px;
    font-size: 13px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
}
  
/* Dark mode switch */

.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbb;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: yellow;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #1F6E8C;
}

input:focus + .slider {
    box-shadow: 0 0 1px #1F6E8C;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 28px;
}

.slider.round:before {
    border-radius: 50%;
}
